import './services/apmService';
import { StrictMode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';

import App from './App';
import apolloClient from './services/apollo/apolloClient';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </StrictMode>
);
