import styled from '@emotion/styled';
import { get } from 'lodash';
import { ReactElement, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';

import { PageLayout } from 'src/components/layouts/Layout';
import { SearchInput } from 'src/components/common/Input/SearchInput';

import GraphPanel from './components/GraphPanel';
import InfoPanel from './components/infoPanel/InfoPanel';
import useTxnData from './hooks/useTxnData';
import { TxnLookupProvider } from './TxnLookupContext';
import { txnSearchIdAtom } from 'src/jotai/atoms';
import { t } from 'src/i18n';

const TxnLookup = (): ReactElement => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const txnIdFromUrl = decodeURIComponent(get(urlParams, 'txnId', ''));
  const location = useLocation();
  const [txnSearchId, setTxnSearchId] = useAtom(txnSearchIdAtom);
  const { data: fullTxnData, getTxnData, isLoading } = useTxnData();
  const hasTxnData = !!get(fullTxnData, 'transactionData.baseTxn.id');
  const hasEmptyResponse = txnSearchId && !isLoading && !hasTxnData;
  const txnId = txnSearchId || txnIdFromUrl;

  useEffect(() => {
    if (txnId) {
      getTxnData(txnId);
    }
  }, []);

  useEffect(() => {
    if (txnId) {
      getTxnData(txnId);
      setTxnSearchId(txnId);
    }
  }, [location.pathname]);

  const handleSubmit = (txnId: string) => {
    navigate(`/txn-lookup/${encodeURIComponent(txnId)}`);
  };

  const handleClear = () => {
    setTxnSearchId('');
    navigate('/txn-lookup');
  };

  const handleChange = (txnId: string) => {
    setTxnSearchId(txnId);
  };

  return (
    <TxnLookupProvider>
      <TxnLookupLayout>
        <SearchInput
          loading={isLoading}
          onClear={handleClear}
          onChange={handleChange}
          onSubmit={handleSubmit}
          placeholder={t('txnLookup.search.placeholder')}
          value={txnId}
          transactionName="txn-search"
        />
        {hasTxnData ? <InfoPanel fullTxnData={fullTxnData} baseTxnId={txnSearchId} /> : null}

        {hasTxnData ? (
          <GraphPanel fullTxnData={fullTxnData} baseTxnId={txnSearchId} />
        ) : hasEmptyResponse ? (
          <NoDataFoundWrapper>{t('common.noDataFound')}</NoDataFoundWrapper>
        ) : null}
      </TxnLookupLayout>
    </TxnLookupProvider>
  );
};

const TxnLookupLayout = styled(PageLayout)`
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: auto 1fr;

  & > :nth-of-type(3) {
    grid-column: 2;
    grid-row: 1 / span 2;
  }
`;

const NoDataFoundWrapper = styled('div')`
  display: flex;
  flex-basis: calc(100% - 400px);
  justify-content: center;
  padding-top: 40px;
  grid-column: 1 / span 2;
`;

export default TxnLookup;
