import { ReactNode, SyntheticEvent } from 'react';
import styled from '@emotion/styled';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

type TabItemConfig = {
  disabled?: boolean;
  label: string;
  tabContent: ReactNode;
  type?: string;
};

interface TabsProps {
  currentTabIndex: number;
  onTabChange?: (index: number) => void;
  tabsConfig: TabItemConfig[];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabsWrapper
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index ? children : null}
    </TabsWrapper>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const TabsSwitcher = (props: TabsProps) => {
  const { currentTabIndex = 0, tabsConfig = [], onTabChange } = props;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    onTabChange?.(newValue);
  };

  return (
    <>
      <StyledTabs value={currentTabIndex} onChange={handleChange} aria-label="tabs" variant='fullWidth'>
        {tabsConfig.map((tabConfig, idx) => (
          <Tab disabled={tabConfig.disabled} label={tabConfig.label} key={tabConfig.label} {...a11yProps(idx)} />
        ))}
      </StyledTabs>
      {tabsConfig.map((tabConfig, idx) => (
        <TabPanel index={idx} key={tabConfig.label} value={currentTabIndex}>
          {tabConfig.tabContent}
        </TabPanel>
      ))}
    </>
  );
};

const TabsWrapper = styled('div')`
  width: 100%;
  flex: 1 1 auto;
`;

const StyledTabs = styled(Tabs)`
  flex: 1 0 auto;
  border-bottom: 1px solid #e0e0e0;
`;

export default TabsSwitcher;
