import { Handle, NodeProps, Position } from 'reactflow';

import useTxnLookupActions, { DETAILS_PANEL_STATE } from '../../../TxnLookupContext';
import { IdWrapper, LabelWrapper, NodeWrapper } from './NodeLayout';
import { NodeColorMapper, TransferNodeProps } from 'src/containers/txnLookup/constants/txnConstants';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

const TransferNode = ({ data, isConnectable }: NodeProps<TransferNodeProps>) => {
  const { onTransferClick, detailsPanelState } = useTxnLookupActions();

  const { itemId } = detailsPanelState;

  const isActive = detailsPanelState.state === DETAILS_PANEL_STATE.transfer && itemId === data.id;

  return (
    <NodeWrapper color={NodeColorMapper.transfer} isActive={isActive} onClick={() => onTransferClick(data.id)}>
      <LabelWrapper>Transfer</LabelWrapper>
      <IdWrapper>{data.shortId}</IdWrapper>
      <div>{stringifyDate(data.createdAt)}</div>
      <LabelWrapper>{data.transferStatus}</LabelWrapper>
      <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
      <Handle type="source" position={Position.Right} isConnectable={isConnectable} />
    </NodeWrapper>
  );
};

export default TransferNode;
