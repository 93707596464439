import { createContext, ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import apm from 'src/services/apmService';

interface TxnLookupContextType {
  closeDetailsPanel: () => void;
  detailsPanelState: DetailsPanelType;
  onLedgerClick: (id: string) => void;
  onPayoutClick: (id: string) => void;
  onDepositClick: (id: string) => void;
  onTransferClick: (id: string) => void;
  onFinancialInstrumentClick: (id: string) => void;
}

export enum DETAILS_PANEL_STATE {
  'hidden',
  'ledger',
  'payout',
  'deposit',
  'transfer',
  'financialInstrument',
}

type DetailsPanelType = {
  state: DETAILS_PANEL_STATE;
  itemId: string | null;
};

const TxnLookupContext = createContext<TxnLookupContextType>({} as TxnLookupContextType);

export function TxnLookupProvider({ children }: { children: ReactNode }): ReactElement {
  const [detailsPanelState, setDetailsPanelState] = useState<DetailsPanelType>({
    state: DETAILS_PANEL_STATE.hidden,
    itemId: null,
  });

  const trackUserInteraction = (transactionName: string, nodeType: string) => {
    const transaction = apm.startTransaction(transactionName, 'user-interaction');

    transaction?.addLabels({ nodeType });
    transaction?.end();
  };

  const memoedValue = useMemo(
    () => ({
      closeDetailsPanel: () => setDetailsPanelState({ state: DETAILS_PANEL_STATE.hidden, itemId: null }),
      detailsPanelState,
      onLedgerClick: (ledgerId: string) => {
        trackUserInteraction('node-view', 'ledger');
        setDetailsPanelState({ state: DETAILS_PANEL_STATE.ledger, itemId: ledgerId });
      },
      onDepositClick: (depositId: string) => {
        trackUserInteraction('node-view', 'deposit');
        setDetailsPanelState({ state: DETAILS_PANEL_STATE.deposit, itemId: depositId });
      },
      onPayoutClick: (payoutId: string) => {
        trackUserInteraction('node-view', 'payout');
        setDetailsPanelState({ state: DETAILS_PANEL_STATE.payout, itemId: payoutId });
      },
      onTransferClick: (transferId: string) => {
        trackUserInteraction('node-view', 'transfer');
        setDetailsPanelState({ state: DETAILS_PANEL_STATE.transfer, itemId: transferId });
      },
      onFinancialInstrumentClick: (financialInstrumentId: string) => {
        trackUserInteraction('node-view', 'financialInstrument');
        setDetailsPanelState({ state: DETAILS_PANEL_STATE.financialInstrument, itemId: financialInstrumentId });
      },
    }),
    [detailsPanelState]
  );

  return <TxnLookupContext.Provider value={memoedValue}>{children}</TxnLookupContext.Provider>;
}

export default function useTxnLookupActions(): TxnLookupContextType {
  return useContext(TxnLookupContext);
}
