import { ReactElement, useState } from 'react';
import { PageLayout } from 'src/components/layouts/Layout';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLazyQuery } from '@apollo/client';
import { ACCOUNT_AUDIT_EVENTS_QUERY } from 'src/services/apollo/queries/auditQueries';
import { SearchInput } from 'src/components/common/Input/SearchInput';
import styled from '@emotion/styled';
import { AuditTable } from './AuditTable';
// TODO: Use codegen generated types

export type AuditEntityState = {
  action: string;
  request?: string;
  response?: string;
};

export type AuthEvent = {
  eventId: string;
  eventType: string;
  eventTime: string;
  traceId: string;
  authType: string;
  authId: string;
};

export type AuditEvent = {
  eventId: string;
  eventType: string;
  eventTime: string;
  traceId: string;
  entityType: string;
  entityId: string;
  entityAction: string;
  entityState?: AuditEntityState;
};

export type AuditEventResponse = {
  entityEvent: AuditEvent;
  authEvents: AuthEvent[];
};

const AuditPage: () => ReactElement = () => {
  const [accountId, setAccountId] = useState<string>('');

  const [getAuditEvents, { loading, data }] = useLazyQuery<{ accountAuditEvents: AuditEventResponse[] }>(
    ACCOUNT_AUDIT_EVENTS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  const auditEvents = data?.accountAuditEvents || [];

  const handleChange = (value: string) => {
    setAccountId(value);
  };

  const handleSearch = () => {
    if (accountId.trim()) {
      getAuditEvents({ variables: { accountId } });
    }
  };

  const handleClear = () => {
    setAccountId('');
  };

  return (
    <PageLayout>
      <SearchInput
        value={accountId}
        placeholder="Enter Account ID"
        onSubmit={handleSearch}
        onClear={handleClear}
        onChange={handleChange}
        width="30rem"
      />
      <p>
        Use <strong>&quot;reportCsvUpload&quot;</strong> to view events from the report service
      </p>

      {loading ? (
        <LoadingText>Loading...</LoadingText>
      ) : (
        auditEvents.map(({ entityEvent, authEvents: [authEvent] }) => (
          <StyledAccordion key={entityEvent.eventId}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              {entityEvent.eventTime} - {entityEvent.entityState?.action} - {authEvent?.authType} - {authEvent.authId}
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <AuditTable entityEvent={entityEvent} authEvent={authEvent} />
            </StyledAccordionDetails>
          </StyledAccordion>
        ))
      )}
    </PageLayout>
  );
};

export const StyledAccordion = styled(Accordion)`
  width: 100%;
  font-family: monospace;
  &.Mui-expanded {
    margin: 0;
  }
  &.MuiAccordion-root {
    &:before {
      display: none;
    }
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: #f0f0f0;
  padding: 0 1rem;
  &.Mui-expanded {
    min-height: 3rem;
    height: 3rem;
  }
  & .MuiAccordionSummary-content {
    margin: 0.75rem 0;
    &.Mui-expanded {
      margin: 0.75rem 0;
    }
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: #f0f0f0;
`;

export const LoadingText = styled.div`
  width: 100%;
  text-align: center;
`;

export default AuditPage;
