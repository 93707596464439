import { ReactElement, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';

import VisibilitySwitcher from '../common/VisibilitySwitcher/VisibilitySwitcher';
import { RESTRICTED_ACCESS } from 'src/constants/securityConstants';

export interface AttrDefType {
  customRender?: (value?: any, data?: any, context?: any) => ReactElement | string;
  hide?: (value: any, data: any) => boolean;
  id: string;
  isCollapsible?: boolean;
  label: string;
  maskValue?: boolean;
}

interface InfoListProps {
  attrDefinitions: AttrDefType[];
  context?: unknown;
  data?: unknown;
}

interface RowLabelProps {
  label: string;
  children: ReactNode;
  isCollapsible?: boolean;
}

const InfoList = ({ attrDefinitions, context, data }: InfoListProps): ReactElement => {
  if (!data) {
    return <NoDataContainer>No Data</NoDataContainer>;
  }

  return (
    <InfoListContainer>
      {attrDefinitions.map(({ id, isCollapsible, label, customRender, hide, maskValue = false }) => {
        const value = get(data, id);

        if (value === RESTRICTED_ACCESS) {
          return null;
        }

        if (hide && hide(value, data)) {
          return null;
        }

        const content = customRender ? customRender(value, data, context) : value;

        return (
          <RowItem isCollapsible={isCollapsible} key={id} label={label}>
            {maskValue ? <VisibilitySwitcher>{content}</VisibilitySwitcher> : content}
          </RowItem>
        );
      })}
    </InfoListContainer>
  );
};

const RowItem = ({ label, children, isCollapsible }: RowLabelProps) => {
  const [open, setOpen] = useState(!isCollapsible);

  const labelContent =
    isCollapsible && children ? (
      <CollapsibleButton
        onClick={() => setOpen(!open)}
        startIcon={open ? <ArrowDropDown fontSize="large" /> : <ArrowRightIcon fontSize="large" />}
      >
        {label}
      </CollapsibleButton>
    ) : (
      label
    );

  const itemContent =
    isCollapsible && children ? (
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
        {children}
      </Collapse>
    ) : (
      children
    );

  return (
    <RowItemContainer>
      <Label>{labelContent}</Label>
      <RowContent>{itemContent}</RowContent>
    </RowItemContainer>
  );
};

const CollapsibleButton = styled(Button)`
  &.MuiButtonBase-root {
    margin-left: -8px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const NoDataContainer = styled.div`
  align-items: center;
  display: flex;
  height: 50vh;
`;

const RowItemContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 13px;
  padding: 4px 12px;

  &:hover {
    background-color: #f6f6f6;
  }
`;

const InfoListContainer = styled.div`
  width: 100%;
  ${RowItemContainer}:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
`;

const Label = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  min-width: 140px;
  margin-right: 16px;
  width: 25%;
`;

const RowContent = styled.div`
  align-items: center;
  display: flex;
  width: 70%;
`;

export default InfoList;
