import { MouseEvent } from 'react';
import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

interface TablePaginationProps {
  count: number;
  isLoading?: boolean;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

interface CurrentItemsInfoProps {
  isLoading: boolean | undefined;
}

function TablePaginationActions(props: TablePaginationProps) {
  const { count, isLoading, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const isLastPage = page >= Math.ceil(count / rowsPerPage) - 1;

  const currentItemsInfo = count ? (
    <CurrentItemsInfo isLoading={isLoading}>{`${page * rowsPerPage + 1}-${
      isLastPage ? count : (page + 1) * rowsPerPage
    } of ${count}`}</CurrentItemsInfo>
  ) : null;

  return (
    <ControlsContainer>
      {currentItemsInfo}
      <Controls>
        <ControlButton onClick={handleBackButtonClick} disabled={isLoading || page === 0}>
          <KeyboardArrowLeft />
        </ControlButton>
        <ControlButton onClick={handleNextButtonClick} disabled={isLoading || isLastPage}>
          <KeyboardArrowRight />
        </ControlButton>
      </Controls>
    </ControlsContainer>
  );
}

export default function TablePagination(props: TablePaginationProps) {
  const recordsInfo = `${props.count || 'No'} record${props.count === 1 ? '' : 's'} found`;

  return (
    <PaginationContainer>
      <PageInfo>{recordsInfo}</PageInfo>
      <TablePaginationActions {...props} />
    </PaginationContainer>
  );
}

const ControlsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
`;

ControlsContainer.displayName = 'ControlsContainer';

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60px;
`;

Controls.displayName = 'Controls';

const CurrentItemsInfo = styled.div<CurrentItemsInfoProps>`
  margin-right: 45px;
  color: ${props => (props.isLoading ? '#ccc' : 'inherit')};
`;

CurrentItemsInfo.displayName = 'CurrentItemsInfo';

const PageInfo = styled.div`
  color: #90a4ae;
  text-transform: capitalize;
`;

PageInfo.displayName = 'PageInfo';

const ControlButton = styled(IconButton)`
  &.MuiButtonBase-root {
    height: 24px;
    width: 24px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  font-family: 'Avenir Next', sans-serif;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
  letter-spacing: 0;
  line-height: 18px;
  margin: 8px 30px 8px 0;
  opacity: 0.54;
  flex-grow: 1;
  align-items: flex-end;
`;

PaginationContainer.displayName = 'PaginationContainer';
