import { ReactElement } from 'react';
import { get } from 'lodash';

import DetailsWrapper from './DetailsWrapper';
import { DetailsProps } from '../constants/infoPanelConstants';
import { DetailsRow, PayoutUrlRow } from '../InfoPanelLayout';
import { PayoutAttrDefs } from 'src/containers/account/components/singlePayout/constants/singlePayoutConstants';
import { useCopy } from 'src/components/common/CopyToClipboard/CopyToClipboard';

const PayoutDetails = ({ accountId, txnData, itemId: payoutId }: DetailsProps): ReactElement => {
  const expandedAttributes = useCopy(['id', 'fundingAgentId', 'transferId', 'transferRequestId'], PayoutAttrDefs);
  const ledgers = txnData?.ledgers || [];
  const ledger = ledgers.find(({ payout }) => payout?.id === payoutId);
  const payout = ledger?.payout;

  if (!payout) {
    return (
      <DetailsWrapper>
        <DetailsRow label="No data found for the payout" value={payoutId} />
      </DetailsWrapper>
    );
  }

  return (
    <DetailsWrapper label="Payout Details">
      {expandedAttributes.map(({ id, label, customRender }) => {
        const value = get(payout, id);

        if (!value) {
          return null;
        }

        return <DetailsRow key={id} label={label} value={customRender ? customRender(value) : value} />;
      })}
      <PayoutUrlRow accountId={accountId} payoutId={payoutId} />
    </DetailsWrapper>
  );
};

export default PayoutDetails;
