import Paper from '@mui/material/Paper/Paper';
import { Link, NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

export const LayoutContainer = styled('div')`
  min-height: 100vh;
`;
LayoutContainer.displayName = 'LayoutContainer';

export const Navigation = styled('menu')`
  width: 100%;
  display: flex;
`;
Navigation.displayName = 'Navigation';

export const PageLayout = styled('main')`
  display: flex;
  height: 100%;
  gap: 0.75rem;
  padding: .625rem;
  flex-wrap: wrap;
  align-content: flex-start;
`;
PageLayout.displayName = 'PageLayout';

export const StyledNavLink = styled(NavLink)`
  color: #5d5d5d;
  padding-right: 54px;
  text-decoration: none;
  text-transform: uppercase;

  &.active {
    color: #fff;
  }
`;
StyledNavLink.displayName = 'StyledNavLink';

export const InternalPageLink = styled(Link)`
  color: #3e6fa3;
  text-decoration: underline;

  &:hover {
    color: #7ca4b3;
  }
`;
InternalPageLink.displayName = 'InternalPageLink';

export const FilledContainer = styled(Paper)`
  padding: 8px 12px 4px;
`;

export const FilledPaddedContainer = styled(Paper)`
  padding: 12px 24px 24px;
`;

export const Header = styled('h2')``;
Header.displayName = 'Header';

export const SectionHeader = styled('h3')`
  color: #555;
`;

export const ActionButtonContainer = styled('div')`
  display: flex;
  justify-content: right;
  margin-bottom: 8px;
  padding-left: 8px;
`;

export const NotFoundContainer = styled('h4')`
  display: flex;
  align-items: center;
  color: #777;
  justify-content: center;
  height: 180px;
  width: 100%;
`;

export const Content = styled('div')`
  display: flex;
`;

export const Actions = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const FiltersWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 42px;
  margin-right: 12px;
  width: 350px;
`;

export const ContentWrapper = styled('div')`
  width: 100%;
  overflow-x: scroll;
`;

export const ActionButtons = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
