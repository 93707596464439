import { useCallback, useState } from 'react';
import { debounce, noop } from 'lodash';
import { SearchInput } from 'src/components/common/Input/SearchInput';
import { t } from 'src/i18n';

const DEBOUNCE_TIME = 1500;

interface TableSearchProps {
  disabled?: boolean;
  searchFunction?: (search: string) => void;
}

export default function TableSearch({ disabled, searchFunction = noop }: TableSearchProps) {
  const [inputText, setInputText] = useState<string>('');

  const debouncedSearchFunction = useCallback(
    debounce(inputText => searchFunction(inputText), DEBOUNCE_TIME),
    []
  );

  const handleClear = () => {
    debouncedSearchFunction.cancel();
    setInputText('');
    searchFunction('');
  };

  const onChange = useCallback(
    (value: string) => {
      setInputText(value);
      debouncedSearchFunction(value);
    },
    [debouncedSearchFunction]
  );

  const handleSubmit = (value: string) => {
    debouncedSearchFunction.cancel();
    searchFunction(value);
  };

  return (
    <SearchInput
      disabled={disabled}
      onChange={onChange}
      onClear={handleClear}
      onSubmit={handleSubmit}
      height="2.125rem"
      width="26.25rem"
      placeholder={t('payouts.search.placeholder')}
      value={inputText}
      disableUnderline
    />
  );
}
