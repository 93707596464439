import { forEach, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useUrlParams = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const [urlParams] = useState<any>(() => new URLSearchParams(search));

  const updateUrlParams = useCallback(
    (entries: Record<string, any>) => {
      if (isEmpty(entries)) {
        navigate({ search: '' });
      } else {
        forEach(entries, (value, key) => {
          value ? urlParams.set(key, value) : urlParams.delete(key); // empty value deletes its entry from params
        });

        navigate({ search: urlParams.toString() });
      }
    },
    [navigate, urlParams]
  );

  return [urlParams, updateUrlParams];
};

export default useUrlParams;
