import { CSSProperties, MouseEvent, ReactElement, ReactNode } from 'react';
import { isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Row from './components/TableRow';
import Spinner, { SpinnerContainer } from './../spinner/Spinner';
import TablePagination from './components/TablePagination';
import TableSearch from './components/TableSearch';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/tableConstants';
import { t } from 'src/i18n';

export interface TableColumnConfigProps {
  attr: string;
  label: string;
  customColumnStyle?: (props?: any) => CSSProperties | Record<string, any>;
  customColumnRenderer?: (props: any) => ReactElement | string;
  customRenderer?: (props: any) => ReactElement | ReactElement[] | string | null;
}

type TableRowProps = Record<string, any>;

interface PaginationControlsType {
  count: number;
  currentPage: number;
  onPageChange: (newPage: number) => void;
}

interface TableProps {
  actions?: ReactNode;
  columnsConfig: TableColumnConfigProps[];
  dataAid?: string;
  editor?: ReactNode;
  editorRouteBase?: string;
  isLoading?: boolean;
  onSearch?: (search: string) => void;
  paginationControls?: PaginationControlsType;
  rows: TableRowProps[];
  rowsPerPage?: number;
  withSearch?: boolean;
}

export default function TableComponent({
  columnsConfig = [],
  dataAid = 'data-table',
  isLoading,
  onSearch,
  paginationControls,
  rows = [],
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  withSearch,
  actions,
}: TableProps) {
  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    paginationControls?.onPageChange(newPage);
  };

  const tableHeader = columnsConfig.map(({ label, customColumnStyle, customColumnRenderer }) => (
    <StyledTableCell key={label} style={customColumnStyle && customColumnStyle()}>
      {customColumnRenderer ? customColumnRenderer(label) : label}
    </StyledTableCell>
  ));

  const isEmptyData = isEmpty(rows);

  const paginationContent =
    paginationControls && !isEmptyData ? (
      <TablePagination
        count={paginationControls.count}
        data-aid={`${dataAid}-pagination`}
        isLoading={isLoading}
        rowsPerPage={rowsPerPage}
        page={paginationControls.currentPage}
        onPageChange={handleChangePage}
      />
    ) : null;

  const getTableContent = () => {
    if (isLoading && isEmptyData) {
      return (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      );
    }

    const tableBody =
      !isLoading && isEmptyData ? (
        <EmptyTable dataAid={dataAid} columnsSpan={columnsConfig.length} />
      ) : (
        rows.map((row: any, idx) => <Row key={`${row.id}-${idx}`} columnConfig={columnsConfig} item={row} />)
      );

    return (
      <Table sx={{ minWidth: 500 }} size="small" data-aid={`${dataAid}-table`}>
        <TableHead>
          <StyledTableRow>{tableHeader}</StyledTableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
    );
  };

  const table = (
    <TableContainer component={Paper} data-aid={`${dataAid}-container`} sx={{ overflowX: 'scroll' }}>
      {getTableContent()}
    </TableContainer>
  );

  const tableSearch = withSearch ? <TableSearch disabled={isLoading} searchFunction={onSearch} /> : null;

  return (
    <>
      <TableActionsWrapper>
        {paginationContent}
        {tableSearch}
        {actions}
      </TableActionsWrapper>
      {table}
    </>
  );
}

interface EmptyTableProps {
  columnsSpan: number;
  dataAid: string;
}

const EmptyTable = ({ columnsSpan, dataAid }: EmptyTableProps) => {
  return (
    <tr data-aid={`${dataAid}-empty-content`}>
      <EmptyTableContent colSpan={columnsSpan}>{t('common.noRecordsFound')}</EmptyTableContent>
    </tr>
  );
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#aaa',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {},
}));
StyledTableCell.displayName = 'StyledTableCell';

interface CustomRowProps {
  isClickable?: boolean;
  onClick?: () => void;
}

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: prop => prop !== 'isClickable',
})<CustomRowProps>`
  border-bottom: 1px solid #ddd;
  height: 32px;
  // hide the last border
  &:last-child th {
    border: 0;
  }

  &:hover {
    background-color: #f3f3f3;
    ${p => (p.isClickable ? 'cursor: pointer;' : '')}
  }
`;
StyledTableRow.displayName = 'StyledTableRow';

export const EmptyTableContent = styled(TableCell)`
  padding: 20px;
`;
StyledTableRow.displayName = 'StyledTableRow';

export const TableActionsWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
