import { gql } from '@apollo/client';

export const UPDATE_BALANCES_MUTATION = gql`
  mutation updateBalances($accountId: ID!, $currency: String) {
    updateBalances(accountId: $accountId, currency: $currency) {
      balances {
        type
        amount
        currency
        createdAt
        updatedAt
      }
    }
  }
`;
