import { ReactElement, useCallback } from 'react';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Spinner from 'src/components/spinner/Spinner';
import { PageLayout } from '../layouts/Layout';

interface CommonViewerProps {
  goBackUrl?: string;
  isLoading: boolean;
  content: ReactElement;
}

const CommonViewer = ({ goBackUrl, isLoading, content }: CommonViewerProps): ReactElement => {
  return (
    <PageLayout>
      <BackLink to={goBackUrl} label="Back" />
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        content
      )}
    </PageLayout>
  );
};

interface BackLinkProps {
  to?: string;
  label: string;
}

const BackLink = ({ to, label }: BackLinkProps): ReactElement => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    to ? navigate(to) : navigate(-1);
  }, [to, navigate]);

  return (
    <CustomLink onClick={onClick}>
      <KeyboardArrowLeftIcon />
      <span>{label}</span>
    </CustomLink>
  );
};

const ContentWrapper = styled('div')`
  align-items: flex-start;
  display: flex;
  padding: 0 24px;
  width: 100%;
`;

ContentWrapper.displayName = 'ContentWrapper';

const CustomLink = styled('span')`
  color: #607d8b;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  left: 0;
  padding: 0.5rem 1.5rem 0.5rem 0;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    color: #7ca4b3;
    cursor: pointer;
  }
`;

CustomLink.displayName = 'CustomLink';

const SpinnerContainer = styled('span')`
  align-items: center;
  display: flex;
  height: min(400px, 50vh);
  justify-content: center;
  width: 100%;
`;

SpinnerContainer.displayName = 'SpinnerContainer';

export default CommonViewer;
