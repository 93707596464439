import { gql } from '@apollo/client';

export const ACCOUNT_AUDIT_EVENTS_QUERY = gql`
  query accountAuditEvents($accountId: ID!) {
    accountAuditEvents(accountId: $accountId) {
      entityEvent {
        eventId
        eventType
        eventTime
        traceId
        entityType
        entityId
        entityAction
        entityState {
          action
          request
          response
        }
      }
      authEvents {
        eventId
        eventType
        eventTime
        traceId
        authType
        authId
      }
    }
  }
`;
