import { useMemo } from 'react';
import ReactFlow, { Controls } from 'reactflow';
import styled from '@emotion/styled';

import 'reactflow/dist/style.css';

import DepositNode from './nodes/DepositNode';
import LedgerNode from './nodes/LedgerNode';
import PayoutNode from './nodes/PayoutNode';
import RootNode from './nodes/RootNode';
import TransferNode from './nodes/TransferNode';
import FinancialInstrumentNode from './nodes/FinancialInstrumentNode';
import { initializeNodes } from '../../helpers/txnLookupHelpers';
import { TxnInfo } from '../../constants/txnConstants';

const nodeMapping = {
  rootNode: RootNode,
  ledgerNode: LedgerNode,
  payoutNode: PayoutNode,
  depositNode: DepositNode,
  transferNode: TransferNode,
  financialInstrumentNode: FinancialInstrumentNode,
} as const;

export type NodeType = keyof typeof nodeMapping;

const TxnGraph = ({ txnData }: { txnData: TxnInfo }) => {
  const { nodes, edges } = useMemo(() => initializeNodes(txnData), [txnData]);

  return (
    <ReactFlowWrapper>
      <ReactFlow
        edges={edges}
        nodes={nodes}
        nodeTypes={nodeMapping}
        zoomOnScroll={false}
        fitView
        fitViewOptions={{ maxZoom: 1 }}
      >
        <Controls showInteractive={false} />
      </ReactFlow>
    </ReactFlowWrapper>
  );
};

const ReactFlowWrapper = styled('div')`
  align-self: stretch;
  background-color: #fff;
  border: 1px solid #ccc;
  display: flex;
  flex-grow: 1;
`;

export default TxnGraph;
