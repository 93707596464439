import styled from '@emotion/styled';
import { get, isEmpty } from 'lodash';
import { ReactElement } from 'react';

import AccountInfo from './AccountInfo';
import TxnGraph from './txnGraph/TxnGraph';
import { FullTxnData } from '../constants/txnConstants';

const GraphPanel = ({ fullTxnData }: { fullTxnData: FullTxnData | null; baseTxnId: string }): ReactElement | null => {
  if (isEmpty(fullTxnData)) {
    return null;
  }

  const txnData = get(fullTxnData, 'transactionData.baseTxn');
  const hasBaseTxnData = !!txnData?.id;

  return (
    <GraphWrapper>
      <AccountInfo data={fullTxnData?.accountData} />
      {hasBaseTxnData ? <TxnGraph txnData={txnData} /> : null}
    </GraphWrapper>
  );
};

const GraphWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export default GraphPanel;
