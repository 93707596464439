import { Handle, NodeProps, Position } from 'reactflow';

import useTxnLookupActions, { DETAILS_PANEL_STATE } from '../../../TxnLookupContext';
import { IdWrapper, LabelWrapper, NodeWrapper } from './NodeLayout';
import { NodeColorMapper, FinancialInstrumentNodeProps } from 'src/containers/txnLookup/constants/txnConstants';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

const FinancialInstrumentNode = ({ data, isConnectable }: NodeProps<FinancialInstrumentNodeProps>) => {
  const { onFinancialInstrumentClick, detailsPanelState } = useTxnLookupActions();

  const { itemId } = detailsPanelState;

  const isActive = detailsPanelState.state === DETAILS_PANEL_STATE.financialInstrument && itemId === data.id;

  return (
    <NodeWrapper
      color={NodeColorMapper.financialInstrument}
      isActive={isActive}
      onClick={() => onFinancialInstrumentClick(data.id)}
    >
      <LabelWrapper>Financial Instrument</LabelWrapper>
      <IdWrapper>{data.shortId}</IdWrapper>
      <div>{stringifyDate(data.createdAt)}</div>
      <LabelWrapper>{data.type}</LabelWrapper>
      <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
    </NodeWrapper>
  );
};

export default FinancialInstrumentNode;
