/* eslint-disable no-process-env */
import { init as initApm } from '@elastic/apm-rum';
import packageJson from '../../package.json';

export default initApm({
  serviceName: 'oberon-frontend',
  serviceVersion: packageJson.version,
  environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
  serverUrl: process.env.REACT_APP_APM_SERVER_URL || 'localhost', // default value is needed to avoid ugly console error
  active: !!process.env.REACT_APP_APM_SERVER_URL,
  logLevel: process.env.NODE_ENV === 'production' ? 'warn' : 'debug',
});
