import { get, isEmpty, reduce, sortBy } from 'lodash';

import { TxnFinancialInstrument, TxnInfo, TxnLedger, TxnPayout, TxnTransfer } from '../constants/txnConstants';
import { NodeType } from '../components/txnGraph/TxnGraph';

export enum TXN_TYPES {
  SALES = 'SALES',
  AUTHORIZATION = 'AUTHORIZATION',
  DECLINE = 'DECLINE',
  REFUND = 'REFUND',
  VOID = 'VOID',
}

export const TXN_TYPE_MAPPER = [
  {
    type: TXN_TYPES.SALES,
    mappings: [
      { action: 'S', status: 'C' },
      { action: 'S', status: 'P' },
      { action: 'S', status: 'R' },
    ],
  },
  {
    type: TXN_TYPES.AUTHORIZATION,
    mappings: [{ action: 'A', status: 'C' }],
  },
  {
    type: TXN_TYPES.DECLINE,
    mappings: [{ action: 'S', status: 'D' }],
  },
  {
    type: TXN_TYPES.REFUND,
    mappings: [{ action: 'R', status: 'R' }],
  },
  {
    type: TXN_TYPES.VOID,
    mappings: [
      { action: null, status: 'V' }, // 'null' for any action
      { action: 'V', status: null }, // 'null' for any status
    ],
  },
] as const;

const TXN_DEFAULT_NODE_COLOR = '#b3ffb5';

const TXN_COLOR_MAPPER = {
  [TXN_TYPES.SALES]: '#008000', // green
  [TXN_TYPES.AUTHORIZATION]: '#0000FF', // blue
  [TXN_TYPES.DECLINE]: '#8B0000', // darkred
  [TXN_TYPES.REFUND]: '#FF5957', // #ff5957
  [TXN_TYPES.VOID]: '#595959', // #595959
} as const;

const yMapper = {
  1: [300],
  2: [200, 400],
  3: [150, 300, 450],
  4: [100, 250, 400, 550],
  5: [75, 200, 325, 450, 575],
  6: [60, 180, 300, 420, 540, 660],
  7: [60, 180, 300, 420, 540, 660, 780],
  8: [60, 180, 300, 420, 540, 660, 780, 900],
  9: [60, 180, 300, 420, 540, 660, 780, 900, 1020],
  10: [60, 180, 300, 420, 540, 660, 780, 900, 1020, 1140],
} as const;

const INITIAL_X_POSITION = -200;
const SPACE_BETWEEN_NODES = 250;

const xMapper = {
  ACCOUNT: INITIAL_X_POSITION + SPACE_BETWEEN_NODES + 5,
  ROOT: INITIAL_X_POSITION + SPACE_BETWEEN_NODES,
  LEDGER: INITIAL_X_POSITION + SPACE_BETWEEN_NODES * 2,
  PAYOUT: INITIAL_X_POSITION + SPACE_BETWEEN_NODES * 3,
  TRANSFER: INITIAL_X_POSITION + SPACE_BETWEEN_NODES * 4,
  FINANCIAL_INSTRUMENT: INITIAL_X_POSITION + SPACE_BETWEEN_NODES * 5,
} as const;

type Position = {
  x: number;
  y: number;
};

type NodeData = { id: string } & Record<string, string | number | boolean>;

type FlowNode = {
  id: string;
  position: Position;
  type: NodeType;
  data: NodeData;
};

export const getTransactionType = (transaction?: TxnInfo) => {
  if (!transaction) {
    return '';
  }

  const { status, action } = transaction || {};

  const typeItem = TXN_TYPE_MAPPER.find(({ mappings }) =>
    mappings.some(
      mapping =>
        (!mapping.action && mapping.status === status) ||
        (!mapping.status && mapping.action === action) ||
        (mapping.action === action && mapping.status === status)
    )
  );

  return typeItem?.type || '';
};

export const getTransactionColor = (transactionType: string) => {
  return get(TXN_COLOR_MAPPER, transactionType, TXN_DEFAULT_NODE_COLOR);
};

// Position Calculation Helpers
const calculateYPosition = (ledgerCount: number, index: number, numberOfLedgers: number) => {
  const yStartPosition = get(yMapper, `${ledgerCount}.${index}`, yMapper[1][0]);
  const yStep =
    ledgerCount > 1 ? get(yMapper, `${ledgerCount}.1`, yMapper[1][0]) - get(yMapper, ledgerCount)[0] : yMapper[1][0];

  return numberOfLedgers > 1 ? yStartPosition + (yStep * (numberOfLedgers - 1)) / 2 : yStartPosition;
};

// Node Generation Helpers
const createNode = (id: string, position: { x: number; y: number }, type: NodeType, data: NodeData): FlowNode => ({
  id,
  position,
  type,
  data,
});

const initDepositNode = (txnData: TxnInfo) => {
  const { deposit } = txnData;

  if (!deposit?.id) {
    return { nodes: [] as any[], edges: [] as any[] };
  }

  const node = createNode('deposit', { x: xMapper.ACCOUNT, y: 100 }, 'depositNode', {
    amount: deposit.amount,
    depositedAt: deposit.depositedAt,
    shortId: (deposit.id || '').substring(0, 16),
    id: deposit.id,
  });

  return {
    nodes: [node],
    edges: [{ id: 'deposit-root', source: 'deposit', target: 'root' }],
  };
};

export const getPayoutNode = (ledgers: TxnLedger[] = [], payout: TxnPayout | null, index: number) => {
  if (!payout?.id) {
    return null;
  }

  const payoutId = payout.id;
  const prevPayoutId = index > 0 && !isEmpty(ledgers) ? get(ledgers, `${index - 1}.payoutId`) : '';

  if (prevPayoutId === payoutId) {
    return null;
  }

  const ledgerCount = ledgers.length;
  const numberOfLedgersWithThisPayout = ledgers.filter(ledger => ledger.payoutId === payoutId).length;
  const yPosition = calculateYPosition(ledgerCount, index, numberOfLedgersWithThisPayout);

  return createNode(`payout${index}`, { x: xMapper.PAYOUT, y: yPosition }, 'payoutNode', {
    id: payoutId,
    shortId: (payoutId || '').substring(0, 16),
    amount: payout.payoutAmount,
    payoutTime: payout.payoutTime,
  });
};

const getTransferNode = (ledgers: TxnLedger[], transfer: TxnTransfer | null, index: number, nodes: FlowNode[]) => {
  if (!transfer?.id) {
    return null;
  }

  const transferId = transfer.id;

  const isFirst = nodes.find(node => node.type === 'transferNode' && node.data.id === transferId) === undefined;

  if (!isFirst) {
    return null;
  }

  const ledgerCount = ledgers.length;
  const numberOfLedgersWithThisPayout = ledgers.filter(ledger => ledger.payout?.transferId === transfer.id).length;
  const yPosition = calculateYPosition(ledgerCount, index, numberOfLedgersWithThisPayout);

  return createNode(`transfer${index}`, { x: xMapper.TRANSFER, y: yPosition }, 'transferNode', {
    id: transfer.id,
    shortId: (transfer.id || '').substring(0, 16),
    createdAt: transfer.createdAt,
    transferStatus: transfer.transferStatus,
  });
};

const getFinancialInstrumentNode = (
  ledgers: TxnLedger[],
  financialInstrument: TxnFinancialInstrument | null,
  index: number,
  nodes: FlowNode[]
) => {
  if (!financialInstrument?.id) {
    return null;
  }

  const financialInstrumentId = financialInstrument.id;

  const isFirst =
    nodes.find(node => node.type === 'financialInstrumentNode' && node.data.id === financialInstrument.id) ===
    undefined;

  if (!isFirst) {
    return null;
  }

  const ledgerCount = ledgers.length;
  const numberOfLedgersWithThisFinancialInstrument = ledgers.filter(
    ledger => ledger.payout?.transfer?.financialInstrumentId === financialInstrumentId
  ).length;
  const yPosition = calculateYPosition(ledgerCount, index, numberOfLedgersWithThisFinancialInstrument);

  return createNode(
    `financialInstrument${index}`,
    { x: xMapper.FINANCIAL_INSTRUMENT, y: yPosition + 10.5 }, // Magic number to align with transfer node
    'financialInstrumentNode',
    {
      id: financialInstrumentId,
      shortId: (financialInstrumentId || '').substring(0, 16),
      createdAt: financialInstrument.createdAt,
    }
  );
};

// Node & Edge Update Helpers
const createLedgerNode = (ledger: TxnLedger, index: number, ledgersLength: number) =>
  createNode(`ledger${index}`, { x: xMapper.LEDGER, y: get(yMapper, `${ledgersLength}.${index}`, 300) }, 'ledgerNode', {
    id: ledger.id,
    amount: ledger.amount,
    ledgeredAt: ledger.ledgeredAt,
    shortId: (ledger.id || '').substring(0, 16),
    type: ledger.type,
  });

const getUpdatedNodes = ({
  nodes,
  ledgers,
  index,
  ledger,
}: {
  nodes: any[];
  ledgers: TxnLedger[];
  index: number;
  ledger: TxnLedger;
}) => {
  const ledgerNode = createLedgerNode(ledger, index, ledgers.length);
  const payoutNode = getPayoutNode(ledgers, ledger.payout, index);
  const transferNode = getTransferNode(ledgers, ledger.payout?.transfer, index, nodes);
  const financialInstrumentNode = getFinancialInstrumentNode(
    ledgers,
    ledger.payout?.transfer?.financialInstrument,
    index,
    nodes
  );

  return [
    ...nodes,
    ledgerNode,
    ...(payoutNode ? [payoutNode] : []),
    ...(transferNode ? [transferNode] : []),
    ...(financialInstrumentNode ? [financialInstrumentNode] : []),
  ];
};

const getLedgerToPayoutEdge = (payoutId: string | null, index: number, nodes: FlowNode[]) => {
  if (!payoutId) {
    return [];
  }

  const payoutNode = nodes.find(node => node.type === 'payoutNode' && node.data.id === payoutId);

  return [
    {
      id: `ledger${index}-payout`,
      source: `ledger${index}`,
      target: payoutNode ? payoutNode.id : `payout${index}`,
    },
  ];
};

const getPayoutToTransferEdge = (payoutId: string | null, transferId: string | null, nodes: any[]) => {
  if (!payoutId || !transferId || nodes.length < 2) {
    return [];
  }

  const payoutNode = nodes.find(node => node.type === 'payoutNode' && node.data.id === payoutId);
  const transferNode = nodes.find(node => node.type === 'transferNode' && node.data.id === transferId);

  if (!payoutNode || !transferNode) {
    return [];
  }

  return [
    {
      id: `${payoutNode.id}-${transferNode.id}`,
      source: payoutNode.id,
      target: transferNode.id,
    },
  ];
};

const getTransferToFinancialInstrumentEdge = (
  transferId: string | null,
  financialInstrumentId: string | null,
  nodes: any[]
) => {
  if (!transferId || !financialInstrumentId || nodes.length < 3) {
    return [];
  }

  const transferNode = nodes.find(node => node.type === 'transferNode' && node.data.id === transferId);
  const financialInstrumentNode = nodes.find(
    node => node.type === 'financialInstrumentNode' && node.data.id === financialInstrumentId
  );

  if (!transferNode || !financialInstrumentNode) {
    return [];
  }

  return [
    {
      id: `${transferNode.id}-${financialInstrumentNode.id}`,
      source: transferNode.id,
      target: financialInstrumentNode.id,
    },
  ];
};

const getUpdatedEdges = ({
  nodes,
  edges,
  index,
  payoutId,
  transferId,
  financialInstrumentId,
}: {
  nodes: any[];
  edges: any[];
  index: number;
  payoutId: string | null;
  transferId: string | null;
  financialInstrumentId: string | null;
}) => {
  const newEdges = [
    { id: `root-ledger${index}`, source: 'root', target: `ledger${index}` },
    ...getLedgerToPayoutEdge(payoutId, index, nodes),
    ...getPayoutToTransferEdge(payoutId, transferId, nodes),
    ...getTransferToFinancialInstrumentEdge(transferId, financialInstrumentId, nodes),
  ];

  const uniqueEdges = newEdges.filter(newEdge => !edges.some(existingEdge => existingEdge.id === newEdge.id));

  return [...edges, ...uniqueEdges];
};

// Main Node Initialization Functions
const initializeConnectedNodes = (txnData: TxnInfo) => {
  const ledgers = (txnData?.ledgers as TxnLedger[]) || [];
  const nodesState = initDepositNode(txnData);

  if (isEmpty(ledgers)) {
    return nodesState;
  }

  const sortedLedgers = sortBy(ledgers, ['payoutId']);

  return reduce(
    sortedLedgers,
    (acc, ledger, index) => {
      const { nodes, edges } = acc;

      const updatesNodes = getUpdatedNodes({ nodes, ledgers: sortedLedgers, index, ledger });

      return {
        nodes: updatesNodes,
        edges: getUpdatedEdges({
          nodes: updatesNodes,
          edges,
          index,
          payoutId: ledger.payoutId,
          transferId: ledger.payout?.transferId,
          financialInstrumentId: ledger.payout?.transfer?.financialInstrumentId,
        }),
      };
    },
    nodesState
  );
};

export const initializeNodes = (txnData: TxnInfo) => {
  const txnType = getTransactionType(txnData);
  const transactionColor = getTransactionColor(txnType);
  const { nodes, edges } = initializeConnectedNodes(txnData);
  const rootNode = createNode(
    'root',
    {
      x: xMapper.ROOT,
      y: 298, // 300-2, compensating for border width
    },
    'rootNode',
    {
      id: (txnData.id || '').substring(0, 8),
      amount: txnData.amount,
      color: transactionColor,
      createdAt: txnData.createdAt,
      hasDeposit: !!txnData.deposit?.id,
      type: getTransactionType(txnData),
    }
  );

  return {
    nodes: [rootNode, ...nodes],
    edges,
  };
};
