import { ReactElement, useCallback, useMemo, useState } from 'react';
import { get } from 'lodash';

import ReportConfigs from './components/reportConfigs/ReportConfigs';
import TabSwitcher from 'src/components/tabs/TabSwitcher';
import useUrlParams from '../account/hooks/useUrlHashParams';
import { PageLayout } from 'src/components/layouts/Layout';

const DEFAULT_TAB_INDEX = 0;

const TabsConfig = {
  reportConfigs: 0,
  abstractGraph: 1,
  graphSummary: 2,
};

const ReportingContainer: () => ReactElement = () => {
  const [urlParams, updateUrlParams] = useUrlParams();
  const currentTabUrlParam = urlParams.get('tab');

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    get(TabsConfig, currentTabUrlParam, DEFAULT_TAB_INDEX)
  );

  const tabsConfig = useMemo(
    () => [
      { label: 'Report Configurations', tabContent: <ReportConfigs />, type: 'reportConfigs' },
      {
        label: 'Abstract Graph',
        tabContent: null,
        type: 'asbtractGraph',
        disabled: true,
      },
      {
        label: 'Graph Summary',
        tabContent: null,
        type: 'graphSummary',
        disabled: true,
      },
    ],
    []
  );

  const onTabChange = useCallback(
    (index: number) => {
      setCurrentTabIndex(index);
      updateUrlParams({
        tab: index === DEFAULT_TAB_INDEX ? null : tabsConfig[index].type,
      });
    },
    [tabsConfig, updateUrlParams]
  );

  const tabSwitcher = (
    <TabSwitcher currentTabIndex={currentTabIndex} onTabChange={onTabChange} tabsConfig={tabsConfig} />
  );

  return <PageLayout>{tabSwitcher}</PageLayout>;
};

export default ReportingContainer;
