import { Handle, NodeProps, Position } from 'reactflow';

import useTxnLookupActions, { DETAILS_PANEL_STATE } from '../../../TxnLookupContext';
import { AmountWrapper, IdWrapper, LabelWrapper, NodeWrapper } from './NodeLayout';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';
import { NodeColorMapper } from 'src/containers/txnLookup/constants/txnConstants';

type LedgerNodeProps = {
  id: string;
  color: string;
  amount: number;
  ledgeredAt: string;
  shortId: string;
  type: string;
};

const LedgerNode = ({ data, isConnectable }: NodeProps<LedgerNodeProps>) => {
  const { onLedgerClick, detailsPanelState } = useTxnLookupActions();

  const { itemId } = detailsPanelState;

  const isActive = detailsPanelState.state === DETAILS_PANEL_STATE.ledger && itemId === data.id;

  return (
    <NodeWrapper
      color={data.color || NodeColorMapper.ledger}
      isActive={isActive}
      onClick={() => onLedgerClick(data.id)}
    >
      <LabelWrapper>{data.type} Ledger</LabelWrapper>
      <IdWrapper>{data.shortId}</IdWrapper>
      <div>{stringifyDate(data.ledgeredAt)}</div>
      <AmountWrapper>{data.amount}</AmountWrapper>
      <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
      <Handle type="source" position={Position.Right} isConnectable={isConnectable} />
    </NodeWrapper>
  );
};

export default LedgerNode;
