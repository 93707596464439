import { ReactElement, useCallback, useMemo, useState } from 'react';
import { get } from 'lodash';

import CommonReport from './components/CommonReport';
import TabSwitcher from 'src/components/tabs/TabSwitcher';
import useUrlParams from '../account/hooks/useUrlHashParams';
import useUserData from 'src/context/UserContext';
import { AggregatedReportType } from './constants/reportConstants';
import { PageLayout } from 'src/components/layouts/Layout';

const DEFAULT_TAB_INDEX = 0;

const TabsConfig = {
  commissionReport: 0,
  salesCompReport: 1,
};

const AggregatedReportsContainer: () => ReactElement = () => {
  const [urlParams, updateUrlParams] = useUrlParams();
  const currentTabUrlParam = urlParams.get('tab');
  const { userAbility } = useUserData();

  const userCanAccessPartnerCommissionReport = userAbility.can('see', 'PARTNERS_COMMISSION_REPORT');
  const userCanAccessSalesCompensationReport = userAbility.can('see', 'SALES_COMPENSATION_REPORT');

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    get(TabsConfig, currentTabUrlParam, DEFAULT_TAB_INDEX)
  );

  const tabsConfig = useMemo(
    () => [
      {
        disabled: !userCanAccessPartnerCommissionReport,
        label: 'Partners Commission Report',
        tabContent: <CommonReport label="Upload OrgId Input CSV" reportType={AggregatedReportType.CommissionReport} />,
        type: 'commissionReport',
      },
      {
        disabled: !userCanAccessSalesCompensationReport,
        label: 'Sales Compensation Report',
        tabContent: (
          <CommonReport
            label="Upload Sales Rep Information and Deal Dates CSV"
            reportType={AggregatedReportType.SalesCompensationReport}
          />
        ),
        type: 'salesCompensationReport',
      },
      {
        label: '',
        tabContent: null,
        type: 'dummyTab',
        disabled: true,
      },
    ],
    [userCanAccessPartnerCommissionReport, userCanAccessSalesCompensationReport]
  );

  const onTabChange = useCallback(
    (index: number) => {
      setCurrentTabIndex(index);
      updateUrlParams({
        tab: index === DEFAULT_TAB_INDEX ? null : tabsConfig[index].type,
      });
    },
    [tabsConfig, updateUrlParams]
  );

  const tabSwitcher = (
    <TabSwitcher currentTabIndex={currentTabIndex} onTabChange={onTabChange} tabsConfig={tabsConfig} />
  );

  return <PageLayout>{tabSwitcher}</PageLayout>;
};

export default AggregatedReportsContainer;
