import { useState } from 'react';
import { useAtom } from 'jotai';
import { accountFiltersAtom } from 'src/jotai/atoms';
import { SearchInput } from 'src/components/common/Input/SearchInput';
import { t } from 'src/i18n';
interface AccountFiltersProps {
  accountIdFromUrl: string;
  loading?: boolean;
  onSubmit: (accountId: string) => void;
}

const AccountFilters = ({ accountIdFromUrl = '', loading, onSubmit }: AccountFiltersProps) => {
  const [accountId, setAccountId] = useState(accountIdFromUrl);
  const [, setFilters] = useAtom(accountFiltersAtom);

  const handleClear = () => {
    setAccountId('');
    setFilters(null);
  };

  const handleSubmit = () => {
    onSubmit(accountId.trim());
  };

  return (
    <SearchInput
      disabled={accountId === accountIdFromUrl}
      loading={loading}
      onChange={setAccountId}
      onClear={handleClear}
      onSubmit={handleSubmit}
      placeholder={t('account.search.placeholder')}
      value={accountId}
      width="26.25rem"
      transactionName="account-search"
    />
  );
};

export default AccountFilters;
