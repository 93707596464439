/* eslint-disable camelcase */
// TODO: Replace with a library import / https://github.com/gdcorp-commerce/l10n
import en_US from './en_US.json';

type I18nDictionary = Record<string, string>;

let currentLocale = 'en_US';

const translations: Record<string, I18nDictionary> = {
  en_US,
};

export const setLocale = (locale: string) => {
  if (translations[locale]) {
    currentLocale = locale;
  }
};

export const t = (key: string): string => {
  const dictionary = translations[currentLocale];

  return dictionary[key] || key;
};

export default {
  t,
  setLocale,
};
