import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logo from 'src/assets/svg/ob_logo.svg';

export interface HeaderProps {
  children?: ReactNode;
}

const AppHeader = ({ children }: HeaderProps) => {
  return (
    <StyledHeader>
      <StyledLink to="/">
        <img src={logo} alt="logo" />
      </StyledLink>
      <HeaderContent>{children}</HeaderContent>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  align-items: center;
  background: #23282a;
  box-sizing: border-box;
  color: white;
  display: flex;
  height: 3.375rem;
  justify-content: space-between;
  left: 0;
  margin: 0 auto;
  padding: 0 0.625rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
`;

StyledHeader.displayName = 'StyledHeader';

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  padding-right: 0.625rem;
`;

StyledLink.displayName = 'StyledLink';

const HeaderContent = styled.menu`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

HeaderContent.displayName = 'HeaderContent';

export default AppHeader;
