import InfoList, { AttrDefType } from 'src/components/infoList/InfoList';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';
import TransferStatusSwitcher from '../TransferStatusSwitcher';
import { get, isNil } from 'lodash';

export const BANK_ACCOUNT_TYPE = 'BANK_ACCOUNT';
export const DEBIT_CARD_TYPE = 'CARD';

export const PayoutAttrDefs: AttrDefType[] = [
  {
    id: 'id',
    label: 'Payout ID',
  },
  {
    id: 'accountId',
    label: 'Account ID',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
  {
    id: 'fundingAgentId',
    label: 'Funding Agent ID',
  },
  {
    id: 'ledgerCount',
    label: 'Ledger Count',
  },
  {
    id: 'payoutAmount',
    label: 'Payout Amount',
  },
  {
    id: 'payoutFee',
    label: 'Payout Fee',
  },
  {
    id: 'payoutTime',
    label: 'Payout Time',
    customRender: stringifyDate,
  },
  {
    id: 'sourceAccountId',
    label: 'Source Account ID',
  },
  {
    id: 'subTotalAmount',
    label: 'Subtotal Amount',
  },
  {
    id: 'transferId',
    label: 'Transfer ID',
  },
  {
    id: 'transferRequestId',
    label: 'Transfer Request ID',
  },
  {
    id: 'transferStatus',
    label: 'Transfer Status',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    customRender: stringifyDate,
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    customRender: stringifyDate,
  },
];

export const TransferAttrDefs: AttrDefType[] = [
  {
    id: 'id',
    label: 'Transfer ID',
  },
  {
    id: 'destinationId',
    label: 'Destination ID',
  },
  {
    id: 'destinationOwnerId',
    label: 'Destination Owner ID',
  },
  {
    id: 'destinationOwnerName',
    label: 'Destination Owner Name',
    maskValue: true,
  },
  {
    id: 'destinationOwnerType',
    label: 'Destination Owner Type',
  },
  {
    id: 'failureCode',
    label: 'Failure Code',
    hide: (value: string | undefined) => isNil(value),
  },
  {
    id: 'failureReason',
    label: 'Failure Reason',
    hide: (value: string | undefined) => isNil(value),
  },
  {
    id: 'returnCode',
    label: 'Return Code',
    hide: (value: string | undefined) => isNil(value),
  },
  {
    id: 'transferProvider',
    label: 'Transfer Provider',
  },
  {
    id: 'sourceId',
    label: 'Source ID',
  },
  {
    id: 'statementDescriptor',
    label: 'Statement Descriptor',
  },
  {
    id: 'transferType',
    label: 'Transfer Type',
  },
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
  {
    id: 'transferStatus',
    label: 'Transfer Status',
    customRender: (status: string, data: any, context: any) => {
      return <TransferStatusSwitcher context={context} data={data} status={status} />;
    },
  },
  {
    id: 'enabledFlags',
    label: 'Enabled Flags',
    hide: (value: string[] | undefined) => isNil(value),
    customRender: (item: string[] = []) => item.join(', '),
  },
  {
    id: 'createdAt',
    label: 'Created At',
    customRender: stringifyDate,
  },
  {
    id: 'effectiveAt',
    label: 'Effective at',
    customRender: stringifyDate,
  },
  {
    id: 'submittedAt',
    label: 'Submitted At',
    customRender: stringifyDate,
  },
  {
    id: 'scheduledAt',
    label: 'Scheduled At',
    customRender: stringifyDate,
  },
  {
    id: 'transferReportId',
    label: 'Transfer Report Id',
  },
  {
    id: 'transferBatchId',
    label: 'Transfer Batch Id',
  },
  {
    id: 'traceNumber',
    label: 'Trace Number',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    customRender: stringifyDate,
  },
];

const BankAccountAttrDefs: AttrDefType[] = [
  {
    id: 'routingNumber',
    label: 'Routing Number',
    maskValue: true,
  },
  {
    id: 'accountNumber',
    label: 'Account Number',
    hide: (value: string | undefined) => isNil(value),
    maskValue: true,
  },
  {
    id: 'accountNumberLastFour',
    label: 'Account Number`s Last 4 Digits',
  },
  {
    id: 'bankName',
    label: 'Bank Name',
  },
  {
    id: 'accountType',
    label: 'Account Type',
  },
  {
    id: 'country',
    label: 'Country',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
];

const DebitCardAttrDefs = [
  {
    id: 'number',
    label: 'Card Number',
    maskValue: true,
  },
  {
    id: 'numberFirst6',
    label: 'Card Number`s First 6 Digits',
  },
  {
    id: 'numberLast4',
    label: 'Card Number`s Last 4 Digits',
  },
  {
    id: 'type',
    label: 'Card type',
  },
  {
    id: 'zipCode',
    label: 'Zip Code',
  },
  {
    id: 'expiryYear',
    label: 'Expiration year',
  },
  {
    id: 'expiryMonth',
    label: 'Expiration month',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
  {
    id: 'country',
    label: 'Country',
  },
];

export const FinInstrumentAttrDefs = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'fingerprint',
    label: 'Fingerprint',
  },
  {
    id: 'name',
    label: 'Name',
    maskValue: true,
  },
  {
    id: 'purpose',
    label: 'Purpose',
    customRender: (item: any = []) => item.join(', '),
  },
  {
    id: 'card',
    label: 'Debit Card',
    customRender: (item: any) => <InfoList data={item} attrDefinitions={DebitCardAttrDefs} />,
    hide: (_: any, data: any) => get(data, 'type') !== DEBIT_CARD_TYPE,
  },
  {
    id: 'bankAccount',
    label: 'Bank Account',
    customRender: (item: any) => <InfoList data={item} attrDefinitions={BankAccountAttrDefs} />,
    hide: (_: any, data: any) => get(data, 'type') !== BANK_ACCOUNT_TYPE,
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'ownerId',
    label: 'Owner Id',
  },
  {
    id: 'ownerType',
    label: 'Owner Type',
  },
  {
    id: 'token',
    label: 'Token',
    hide: (value: string | undefined) => isNil(value),
  },
  {
    id: 'tokenSource',
    label: 'Token Source',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    customRender: stringifyDate,
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    customRender: stringifyDate,
  },
];
