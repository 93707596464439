import styled from '@emotion/styled';
import { ReactElement } from 'react';

import PanelRow from './infoPanel/components/PanelRow';
import { TxnAccountInfo } from '../constants/txnConstants';
import { InternalPageLink } from 'src/components/layouts/Layout';

const AccountInfo = ({ data }: { data: TxnAccountInfo }): ReactElement => {
  const enabledFeatures = data?.enabledFeatures || [];
  const accountId = data?.id;

  const accountName = accountId ? (
    <InternalPageLink
      to={`/account?reqIdType=account&accountId=${encodeURIComponent(accountId)}`}
      rel="noopener"
      target="_blank"
    >
      {data?.name}
    </InternalPageLink>
  ) : (
    data?.name
  );

  // TODO - Uncomment PayoutStatus component when the API supports the 'fundingAgents' field
  // const payoutStatus = <PayoutStatus data={data} />;

  return (
    <AccountInfoWrapper>
      <AccountInfoHeader>Account info</AccountInfoHeader>
      <PanelRow label="Biz Name:" value={accountName} />
      <PanelRow label="Status:" value={data?.status} />
      <PanelRow label="Enabled Features:" value={enabledFeatures.join(', ')} />
      {/* <PanelRow label="Payout Status:" value={payoutStatus} /> */}
    </AccountInfoWrapper>
  );
};

const AccountInfoWrapper = styled('div')`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0 1px 3px -1px;
  min-width: 550px;
  padding: 4px 12px 8px;
  margin-bottom: .75rem;
`;

const AccountInfoHeader = styled('p')`
  font-weight: 600;
  margin: 6px 0;
  text-decoration: underline;
  text-transform: uppercase;
`;

export default AccountInfo;
