import { ReactElement, useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';

import ReportDownloader from '../common/ReportDownloader';
import Table from 'src/components/table/Table';
import useAccountSearch from './hooks/useAccountSearch';
import usePayoutsData, { usePayoutsDataLoader } from '../../hooks/usePayoutsData';
import { getAccountPayoutsColumnsConfig } from './helpers/accountHelpers';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/tableConstants';
import { FilterValuesType, REQUEST_ID_TYPE_KEY } from 'src/constants/filterConstants';

interface AccountPayoutsProps {
  filters?: FilterValuesType | null;
}

const PAYOUTS_CSV_HEADER_ROWS_CONFIG = {
  id: 'Payout ID',
  transferId: 'Transfer ID',
  transferStatus: 'Transfer Status',
  subTotalAmount: 'Subtotal Amount',
  payoutAmount: 'Payout Amount',
  currency: 'Currency',
  payoutTime: 'Payout Time',
  effectiveAt: 'Effective At',
};

export default function AccountPayouts({ filters }: AccountPayoutsProps): ReactElement {
  const [currentPage, setCurrentPage] = useState(0);

  const requestIdType = get(filters, REQUEST_ID_TYPE_KEY, '');
  const accountId = get(filters, requestIdType, '');

  const { searchFunction, searchResult } = useAccountSearch(accountId, requestIdType);
  const { isLoading, loadData: loadDataWithProgress, data } = usePayoutsData();
  const { loadData: payoutsDataLoader } = usePayoutsDataLoader();

  const items = get(data, 'nodes', []);
  const itemsCount = get(data, 'count', 0);

  useEffect(() => {
    setCurrentPage(0);
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    loadDataWithProgress({ filters, offset: currentPage * DEFAULT_ROWS_PER_PAGE });
  }, [JSON.stringify(filters), currentPage]);

  const changePageHandler = useCallback((newPage: number) => {
    setCurrentPage(newPage);
  }, []);

  const columnsConfig = getAccountPayoutsColumnsConfig({
    editorRouteBase: `/account/${encodeURIComponent(accountId)}/type/${encodeURIComponent(requestIdType)}/payout`,
  });

  const actions = (
    <ReportDownloader
      disabled={!itemsCount || isLoading}
      headerRowsConfig={PAYOUTS_CSV_HEADER_ROWS_CONFIG}
      filters={filters}
      loadData={payoutsDataLoader}
      maxLimit={itemsCount}
      reportType="Payout"
    />
  );

  return (
    <>
      <Table
        columnsConfig={columnsConfig}
        isLoading={isLoading}
        onSearch={searchFunction}
        rows={searchResult ? searchResult : items}
        rowsPerPage={DEFAULT_ROWS_PER_PAGE}
        paginationControls={{
          count: itemsCount,
          currentPage,
          onPageChange: changePageHandler,
        }}
        withSearch
        actions={actions}
      />
    </>
  );
}
