import { ReactElement } from 'react';
import { get } from 'lodash';

import DetailsWrapper from './DetailsWrapper';
import { DetailsRow, PayoutUrlRow } from '../InfoPanelLayout';
import { DetailsProps } from '../constants/infoPanelConstants';
import { FinInstrumentAttrDefs } from 'src/containers/account/components/singlePayout/constants/singlePayoutConstants';
import { useCopy } from 'src/components/common/CopyToClipboard/CopyToClipboard';

const FinancialInstrumentDetails = ({
  accountId,
  txnData,
  itemId: financialInstrumentId,
}: DetailsProps): ReactElement => {
  const expandedAttributes = useCopy(['id', 'ownerId'], FinInstrumentAttrDefs);
  const ledgers = txnData?.ledgers || [];
  const ledger = ledgers.find(({ payout }) => payout?.transfer?.financialInstrumentId === financialInstrumentId);
  const payoutId = ledger?.payout?.id;
  const financialInstrument = ledger?.payout?.transfer?.financialInstrument;

  if (!financialInstrument) {
    return (
      <DetailsWrapper>
        <DetailsRow label="No data found for the financial instrument" value={financialInstrumentId} />
      </DetailsWrapper>
    );
  }

  return (
    <DetailsWrapper label="Financial Instrument Details">
      {expandedAttributes.map(({ id, label, hide, customRender }) => {
        const value = get(financialInstrument, id);

        if (!value || (hide && hide(value, financialInstrument))) {
          return null;
        }

        return <DetailsRow key={id} label={label} value={customRender ? customRender(value) : value} />;
      })}
      <PayoutUrlRow accountId={accountId} payoutId={payoutId} />
    </DetailsWrapper>
  );
};

export default FinancialInstrumentDetails;
