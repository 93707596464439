import { ReactElement, useState, useEffect } from 'react';
import { get } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';

import Table from 'src/components/table/Table';
import { ACCOUNT_BALANCES_QUERY } from 'src/services/apollo/queries/accountQueries';
import { UPDATE_BALANCES_MUTATION } from 'src/services/apollo/mutations/updateBalances';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';
import { ActionButtonContainer, ActionButtons, Actions, Content, ContentWrapper } from 'src/components/layouts/Layout';
import { useGlobalProgressBar } from 'src/context/GlobalProgressBarContext';

interface AccountLedgersProps {
  // TODO Add proper type
  accountData?: any;
}

export default function AccountBalances({ accountData }: AccountLedgersProps): ReactElement {
  const accountId = get(accountData, 'id', '');
  const isLogicalAccount = get(accountData, 'type') === 'LOGICAL';
  const [balances, setBalances] = useState<Record<string, any>[]>([]);
  const { setIsLoading } = useGlobalProgressBar();

  const { loading: balancesLoading, data: response } = useQuery(ACCOUNT_BALANCES_QUERY, {
    variables: {
      accountId,
    },
  });

  const [updateBalances, { loading: updateBalancesLoading }] = useMutation(UPDATE_BALANCES_MUTATION);

  useEffect(() => {
    setBalances(get(response, 'accountBalances') || []);
  }, [response]);

  if (!isLogicalAccount) {
    return <div>Balances are available only for accounts of logical type</div>;
  }

  const columnsConfig = [
    {
      attr: 'type',
      label: 'Type',
    },
    {
      attr: 'amount',
      label: 'amount',
      customColumnStyle: () => ({ textAlign: 'right' }),
    },
    {
      attr: 'currency',
      label: 'currency',
      customColumnStyle: () => ({ textAlign: 'center' }),
    },
    {
      attr: 'createdAt',
      label: 'created at',
      customRenderer: (item: any) => stringifyDate(get(item, 'createdAt')),
    },
    {
      attr: 'updatedAt',
      label: 'updated at',
      customRenderer: (item: any) => stringifyDate(get(item, 'updatedAt')),
    },
  ];

  const handleRefreshBalances = async () => {
    setIsLoading(true);

    try {
      const result = await updateBalances({
        variables: {
          accountId,
        },
      });

      setBalances(get(result, 'data.updateBalances.balances') || []);
    } finally {
      setIsLoading(false);
    }
  };

  const loading = balancesLoading || updateBalancesLoading;

  return (
    <Content>
      <ContentWrapper>
        <Actions>
          <ActionButtons>
            <ActionButtonContainer>
              <Button variant="outlined" onClick={handleRefreshBalances} disabled={loading}>
                Refresh Balances
              </Button>
            </ActionButtonContainer>
          </ActionButtons>
        </Actions>
        <Table columnsConfig={columnsConfig} isLoading={loading} rows={balances} />
      </ContentWrapper>
    </Content>
  );
}
