import styled from '@mui/material/styles/styled';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper/Paper';
import TextField from '@mui/material/TextField';
import apm from 'src/services/apmService';

interface SearchInputProps {
  disabled?: boolean;
  loading?: boolean;
  onClear?: () => void;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  placeholder: string;
  value: string;
  width?: string;
  height?: string;
  disableUnderline?: boolean;
  transactionName?: string;
}

const SearchButton = styled(Button)`
  min-width: 1.5rem;
  min-height: 1.5rem;
  padding: 0.5rem;
`;

export const SearchWrapper = styled(Paper)<{ width?: string; height?: string }>`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.25rem 0 0.25rem 0.75rem;
  width: ${props => props.width ?? 'auto'};
  height: ${props => props.height ?? '2.875rem'};
`;

export const SearchInput = ({
  disabled,
  loading,
  onClear,
  onChange,
  onSubmit,
  placeholder,
  value,
  width,
  height,
  disableUnderline = false,
  transactionName,
}: SearchInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || '';

    onChange(value);
  };

  const trackSearchInteraction = (searchValue: string, userAction: string, transactionName?: string) => {
    const transaction = transactionName ? apm.startTransaction(transactionName, 'user-interaction') : undefined;

    transaction?.addLabels({
      userAction,
    });

    transaction?.end();
  };

  const handleSubmit = (searchValue: string, userAction: string) => {
    trackSearchInteraction(searchValue, userAction, transactionName);
    onSubmit(searchValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit(value, 'Enter');
    }
  };

  const handleClick = () => {
    handleSubmit(value, 'Click');
  };

  const disableActionButton = !value || disabled;
  const showClearButton = value.length > 0 && onClear;

  return (
    <SearchWrapper width={width} height={height}>
      <TextField
        autoFocus
        disabled={loading}
        fullWidth
        onChange={handleChange}
        placeholder={placeholder}
        required
        type="text"
        variant="standard"
        value={value}
        InputProps={{
          disableUnderline,
          inputProps: {
            style: disableUnderline ? { padding: 0 } : undefined,
          },
          ...(showClearButton && {
            endAdornment: (
              <IconButton
                disabled={!value || loading}
                onClick={onClear}
                size="small"
                sx={disableUnderline ? undefined : { position: 'absolute', right: -2, top: -3 }}
              >
                <CloseIcon />
              </IconButton>
            ),
          }),
        }}
        onKeyDown={handleKeyDown}
      />
      <SearchButton
        color="primary"
        disabled={disableActionButton || loading}
        disableElevation
        onClick={handleClick}
        type="submit"
      >
        <SearchIcon />
      </SearchButton>
    </SearchWrapper>
  );
};
