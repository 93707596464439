import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import styled from '@emotion/styled';
import { AuditEvent, AuthEvent } from './Audit';

interface AuditTableProps {
  entityEvent: AuditEvent;
  authEvent: AuthEvent;
}

export const AuditTable = ({ entityEvent, authEvent }: AuditTableProps) => (
  <StyledTable>
    <TableHead>
      <TableRow>
        <TableCell colSpan={2}>
          <strong>Account Information</strong>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TitleCell>Account ID</TitleCell>
        <TableCell>{entityEvent.entityId}</TableCell>
      </TableRow>
      {entityEvent.entityState && (
        <>
          <TableRow>
            <TitleCell>
              <strong>Action</strong>
            </TitleCell>
            <TableCell>
              <strong>{entityEvent.entityState.action}</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TitleCell>Request</TitleCell>
            <TableCell>
              {entityEvent.entityState.request
                ? JSON.stringify(JSON.parse(entityEvent.entityState.request), null, '\t')
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TitleCell>Response</TitleCell>
            <TableCell>
              {entityEvent.entityState.response
                ? JSON.stringify(JSON.parse(entityEvent.entityState.response), null, '\t')
                : ''}
            </TableCell>
          </TableRow>
        </>
      )}
      <TableRow>
        <TitleCell>Trace ID</TitleCell>
        <TableCell>{entityEvent.traceId}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}>
          <strong>Performed By</strong>
        </TableCell>
      </TableRow>
      <TableRow>
        <TitleCell>Auth Type</TitleCell>
        <TableCell>{authEvent?.authType}</TableCell>
      </TableRow>
      <TableRow>
        <TitleCell>Auth ID</TitleCell>
        <TableCell>{authEvent?.authId}</TableCell>
      </TableRow>
      <TableRow>
        <TitleCell>Time</TitleCell>
        <TableCell>{authEvent?.eventTime}</TableCell>
      </TableRow>
    </TableBody>
  </StyledTable>
);

const TitleCell = styled(TableCell)`
  width: 10rem;
`;

const StyledTable = styled(Table)`
  font-family: monospace;
`;
