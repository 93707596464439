import { ApmRoutes } from '@elastic/apm-rum-react';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider as StateProviver } from 'jotai';
import { Fragment, ReactElement } from 'react';
import { BrowserRouter as Router, Navigate, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { Normalize } from 'styled-normalize';

import Account from './containers/account/Account';
import AggregatedReports from './containers/aggregatedReports/AggregatedReports';
import AppHeader from './components/appHeader/AppHeader';
import Audit from './containers/audit/Audit';
import ErrorBoundary from './components/layouts/ErrorBoundary';
import GlobalStyles from './GlobalStyles';
import MainMenu from './containers/mainMenu/MainMenu';
import NotFoundContainer from './components/layouts/NotFound';
import Reporting from './containers/reporting/Reporting';
import SingleAccountPayout from './containers/account/components/singlePayout/SingleAccountPayout';
import SingleLedger from './containers/account/components/singleLedger/SingleLedger';
import TxnLookup from './containers/txnLookup/TxnLookup';
import UserInfoContainer from './containers/userInfo/UserInfo';
import useUserData, { UserProvider } from './context/UserContext';
import withNetworkHandler from 'src/hoc/withNetworkHandler';

import { GlobalProgressBar } from './components/progress/GlobalProgressBar';
import { GlobalProgressBarProvider } from './context/GlobalProgressBarContext';
import { theme } from './theme/theme';
import PayfacTools from './containers/payfacTools/PayfacTools';

const App = (): ReactElement => (
  <Fragment>
    <Normalize />
    <GlobalStyles />

    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StateProviver>
          <UserProvider>
            <GlobalProgressBarProvider>
              <GlobalProgressBar />
              <ToastContainer />
              <AppContent />
            </GlobalProgressBarProvider>
          </UserProvider>
        </StateProviver>
      </ThemeProvider>
    </ErrorBoundary>
  </Fragment>
);

const AppContent = (): ReactElement => {
  const { user } = useUserData();

  const isUserAuthenticated = user?.isAuthenticated;

  return (
    <Router>
      <AppHeader>
        {isUserAuthenticated ? (
          <>
            <MainMenu />
            <UserInfoContainer />
          </>
        ) : null}
      </AppHeader>
      {isUserAuthenticated ? (
        <ApmRoutes>
          <Route index element={<Navigate replace to="/account" />} />
          <Route path="/account/:accountId/type/:reqIdType/payout/:payoutId" Component={SingleAccountPayout} />
          <Route path="/account/:accountId/type/:reqIdType/ledger/:ledgerId" Component={SingleLedger} />
          <Route path="/account" Component={Account} />
          <Route path="/payfac-tools" Component={PayfacTools} />
          <Route path="/aggregated-reports" Component={AggregatedReports} />
          <Route path="/txn-lookup/:txnId" Component={TxnLookup} />
          <Route path="/txn-lookup" Component={TxnLookup} />
          <Route path="/reporting-service" Component={Reporting} />
          <Route path="/audit" Component={Audit} />
          <Route path="*" Component={NotFoundContainer} />
        </ApmRoutes>
      ) : null}
    </Router>
  );
};

export default withNetworkHandler(App);
