import { ReactElement } from 'react';
import { get } from 'lodash';

import DetailsWrapper from './DetailsWrapper';
import { DetailsProps } from '../constants/infoPanelConstants';
import { DetailsRow, PayoutUrlRow } from '../InfoPanelLayout';
import { TransferAttrDefs } from 'src/containers/account/components/singlePayout/constants/singlePayoutConstants';
import { useCopy } from 'src/components/common/CopyToClipboard/CopyToClipboard';

const TransferDetails = ({ accountId, txnData, itemId: transferId }: DetailsProps): ReactElement => {
  const expandedAttributes = useCopy(
    ['id', 'destinationId', 'destinationOwnerId', 'sourceId', 'transferReportId', 'transferBatchId'],
    TransferAttrDefs
  );
  const ledgers = txnData?.ledgers || [];
  const ledger = ledgers.find(({ payout }) => payout?.transferId === transferId);
  const payoutId = ledger?.payout?.id;
  const transfer = ledger?.payout?.transfer;

  if (!transfer) {
    return (
      <DetailsWrapper>
        <DetailsRow label="No data found for the transfer" value={transferId} />
      </DetailsWrapper>
    );
  }

  return (
    <DetailsWrapper label="Transfer Details">
      {expandedAttributes.map(({ id, label, hide, customRender }) => {
        const value = get(transfer, id);
        const useCustomRender = id !== 'transferStatus';

        if (!value || (Array.isArray(value) && value.length === 0) || (hide && hide(value, transfer))) {
          return null;
        }

        return (
          <DetailsRow key={id} label={label} value={customRender && useCustomRender ? customRender(value) : value} />
        );
      })}
      <PayoutUrlRow accountId={accountId} payoutId={payoutId} />
    </DetailsWrapper>
  );
};

export default TransferDetails;
